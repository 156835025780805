import { getType } from '@/utils';

/**
 * Clear value by type
 *
 * @param {*} value
 * @param {boolean} skip
 * @returns {*}
 */
export const clearValue = function clearValue(value, skip = true) {
  switch (getType(value)) {
    case 'object':
      value = {};
      break;
    case 'string':
      value = '';
      break;
    case 'number':
      value = 0;
      break;
    case 'bigint':
      value = 0n;
      break;
    case 'boolean':
      value = false;
      break;
    case 'null':
      value = null;
      break;
    case 'array':
      value = [];
      break;
  }

  return skip ? value : undefined;
};

/**
 * Clear object properties by type
 *
 * @param {object} obj
 * @param {boolean} recursive
 * @returns {*}
 */
export const clearObjectProperties = function clearObjectProperties(
  obj,
  recursive = false
) {
  Object.keys(obj).forEach(function (key) {
    if (recursive && getType(obj[key]) === 'object') {
      return clearObjectProperties(obj[key], recursive);
    }

    obj[key] = clearValue(obj[key]);
  });

  return obj;
};
