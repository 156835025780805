<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{ $t('PERSONS.PERSONS_MANAGEMENT') }}</v-toolbar-title>
    </v-toolbar>

    <v-sheet class="mt-5 pa-5" tile>
      <persons-list
        :persons="persons"
        @show="showedPerson"
        @refresh="refreshData"
      />
    </v-sheet>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PersonsList from '@/modules/persons/components/PersonsList';

export default {
  name: 'PersonsPage',

  components: {
    PersonsList,
  },

  data: () => ({
    person: null,
    persons: undefined,
  }),

  mounted() {
    this.getPersons();
  },

  methods: {
    ...mapActions('person', ['getPersons']),

    refreshData(params) {
      this.getPersons(params)
        .then(({ data }) => {
          this.persons = data;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    showedPerson({ id }) {
      this.$router.push({ name: 'persons.show', params: { id } });
    },
  },
};
</script>
