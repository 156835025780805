<template>
  <v-form>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="persons.data"
      :server-items-length="persons.meta.total"
      sort-desc
      :footer-props="footerProps"
      :options.sync="options"
      sort-by="id"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="1"></td>
          <td>
            <v-text-field
              v-model="filters.first_name"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.last_name"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.street"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.zip"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.city"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.mobile_phone"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.email"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              v-model="filters.building_complexes"
              :label="$t('DATA_TABLE.FILTER')"
              :search-input.sync="building_complex"
              :items="building_complexes"
              :loading="loading"
              item-text="name"
              item-value="id"
              :no-data-text="$t('MESSAGE.NO_DATA_FILTER_TEXT')"
              clearable
              multiple
              chips
              small-chips
              deletable-chips
              v-on="{
                change: [() => (building_complex = '')],
              }"
            ></v-autocomplete>
          </td>
          <td>
            <v-select
              v-model="filters.statuses"
              :items="statusesList"
              item-text="name"
              item-value="id"
              :label="`${$t('ITEM.STATUS')} `"
              :loading="loading"
              chips
              small-chips
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item, filters.statuses)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </td>
          <td>
            <div class="d-flex">
              <v-btn color="grey" depressed outlined @click="resetFilters">
                {{ $t('BUTTON.CLEAR_FILTER') }}
              </v-btn>
              <v-spacer class="mx-1"></v-spacer>
              <v-btn
                color="primary"
                depressed
                type="submit"
                @click.prevent="find"
              >
                {{ $t('BUTTON.FIND') }}
              </v-btn>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip dark :color="normalizedStatusesList[item.status_id].color">
          {{ normalizedStatusesList[item.status_id].name }}
        </v-chip>
      </template>

      <template
        v-slot:item.inhabitant.apartment.building.building_complex.name="{
          item,
        }"
      >
        <span class="mr-2">{{
          item.inhabitant.apartment.building.building_complex.name
        }}</span>
        <router-link
          v-if="item.inhabitant.apartment.building.building_complex_id"
          class="text-decoration-none"
          target="_blank"
          :to="{
            name: 'building_complexes.show',
            params: {
              id: item.inhabitant.apartment.building.building_complex_id,
            },
          }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on">mdi-eye-outline</v-icon>
            </template>
            <span
              v-if="item.inhabitant.apartment.building.building_complex.name"
              >{{
                item.inhabitant.apartment.building.building_complex.name
              }}</span
            >
            <span v-else>{{ $t('ITEM.SHOW') }}</span>
          </v-tooltip>
        </router-link>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item @click="$emit('show', item)">
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-eye</v-icon>
              {{ $t('ITEM.VIEW') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';
import { isEmpty, cloneDeep } from 'lodash';
import { clearObjectProperties } from '@/helpers/casting';

export default {
  name: 'PersonsList',

  props: {
    persons: {
      type: Object,
      default: () => ({
        data: [],
        meta: {
          total: 0,
        },
      }),
    },
  },

  data: () => ({
    error: null,
    options: {},
    filters: {
      first_name: '',
      last_name: '',
      street: '',
      zip: '',
      city: '',
      mobile_phone: '',
      email: '',
      building_complexes: [],
      statuses: [],
    },
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
    roles: [],
    statusesList: [],
    building_complex: '',
    building_complexes: [],
  }),

  computed: {
    ...mapState('person', {
      prevOptions: 'options',
      prevFilters: 'filters',
    }),
    loading() {
      return this.$store.getters['loading'];
    },
    normalizedStatusesList() {
      return this.statusesList.reduce((acc, status) => {
        acc[status.id] = status;

        return acc;
      }, {});
    },
    params() {
      const options = this.options;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },

    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('PROFILE.FIRST_NAME'),
          value: 'first_name',
        },
        {
          text: this.$t('PROFILE.LAST_NAME'),
          value: 'last_name',
        },
        {
          text: this.$t('PROFILE.STREET'),
          value: 'personal_profile.street',
        },
        {
          text: this.$t('PROFILE.ZIP'),
          value: 'personal_profile.zip',
        },
        {
          text: this.$t('PROFILE.CITY'),
          value: 'personal_profile.city',
        },
        {
          text: this.$t('PROFILE.MOBILE_PHONE'),
          value: 'personal_profile.mobile_phone',
        },
        {
          text: this.$t('PROFILE.EMAIL'),
          value: 'user.email',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.ITEM'),
          value: 'inhabitant.apartment.building.building_complex.name',
        },
        {
          text: this.$t('PROFILE.STATUS'),
          value: 'status',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },

  created() {
    this.getStatuses().then(({ data }) => {
      this.statusesList = data;
    });

    if (!isEmpty(this.prevFilters)) {
      this.filters = cloneDeep(this.prevFilters);
    }

    if (!isEmpty(this.prevOptions)) {
      this.options = cloneDeep(this.prevOptions);
    }
    this.getDataBuildingComplexes();
  },

  beforeDestroy() {
    this.$store.commit('person/pageFilters', cloneDeep(this.filters));
    this.$store.commit('person/pageOptions', cloneDeep(this.options));
  },

  methods: {
    ...mapActions('person', ['getFilteredBuildingComplexes']),
    ...mapCacheActions('person', ['getStatuses']),
    refreshData() {
      this.$emit('refresh', {
        params: Object.assign({}, this.filters, this.params),
      });
    },
    remove(item, array) {
      const index = array.indexOf(item.id);

      if (index >= 0) {
        array.splice(index, 1);
      }
    },
    find() {
      this.options.page = 1;
      this.refreshData();
    },
    resetFilters() {
      clearObjectProperties(this.filters);
      this.find();
    },
    getDataBuildingComplexes() {
      this.getFilteredBuildingComplexes({ search: this.building_complex })
        .then(({ data }) => {
          this.building_complexes = data;
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>
